import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../components/layout';

export default function Features() {
  return (
    <Layout
      title={`Features`}
    >
      <div className='root' style={{ paddingTop: 40 }}>
        <Container maxWidth='md'>
          <section style={{ textAlign: 'left', marginTop: '3%' }}>
            <h1>Tabmanager.io Features Overview</h1>
          </section>

          <div style={{ marginBottom: 40, marginTop: 40 }}>
            <img src='/images/tm-banner.svg' alt='Tabmanager.io banner' width='100%' />
          </div>

          <section style={{ marginTop: 30 }}>
            <h2>What is Tabmanager.io?</h2>
            <p>
              Tabmanager.io is an all-in-one window, session and tab-manager extension that helps users manage
              and organize open tabs and windows in one convenient place.
            </p>
          </section>

          <section style={{ marginTop: 20 }}>
            <h2>Features</h2>

            <h3 style={{ marginTop: 20 }}>Window & Tab Manager</h3>
            <p>
              Our window and tab manager provides you with the ability to quickly navigate to open windows and tabs
              with mouse or keyboard. Switch easily between tabs and windows. Search and filter for tabs by title
              or URL keywords. Manage pinned tabs, sort tabs and windows, group tabs by domain, suspend tabs,
              find tabs playing sound, and remove duplicates.You can also export tabs and windows to file as backup
              or save tabs for later and sync them across your devices.
            </p>
            <p>
              The manager's multiple column UI layout also makes it easy to get an overview of all your open windows
              and tabs and organize your windows by renaming them. With drag and drop you can easily move tabs between
              windows, open in a new window or merge entire windows.
            </p>
            <p>
              The tab manager also comes with features so that you can save tabs for later restore by saving specific
              tabs in window, save all tabs in a window or save all open windows.
            </p>

            <h3>Saved Windows</h3>
            <p>
              Provides an overview of all your saved windows allowing you to easily manage and quickly
              restore previously saved windows. Our smart restore avoids slowing down your computer by
              opening tabs in a suspended state in a new or existing window. You can also sync your saved
              windows to the cloud to prevent losing any data and accessing them from all your devices.
            </p>

            <h3>Session History</h3>
            <p>
              Get a timeline history overview of your browser sessions and easily restore individual windows or tabs
              from an earlier point in time. Save session snapshots from the manager or the menu for when you want
              to quit your browser, or temporarily need a fresh browser, without the fear of losing all your tabs and windows. Enable auto-save session to always have a backup of your browser session in case your browser crashes.
            </p>
          </section>

          <section style={{ marginTop: 20 }}>
            <h2>Who is Tabmanager.io for?</h2>
            <p>
              Tabmanager.io was design for people doing a lot of online research and other professions where there is a
              need to handle a large amount of tabs or manage window workspaces.
            </p>
            <p>
              <li>Students</li>
              <li>Researchers</li>
              <li>Academia</li>
              <li>Journalists</li>
              <li>Writers</li>
              <li>Recruiters</li>
              <li>Engineers</li>
              <li>Software Developers</li>
              <li>Content Creators</li>
            </p>
          </section>

          <section style={{ marginTop: 20 }}>
            <h2>Browser Support</h2>
            <p>
              Tabmanager.io run on all Chromium based browsers including:
              <li>Google Chrome</li>
              <li>Microsoft Edge</li>
              <li>Vivaldi</li>
              <li>Opera</li>
            </p>
          </section>

        </Container>
      </div>
    </Layout>
  );
}
